import ReferralSettings from './ReferralSettings.vue';
import Referral from './Referral.vue';
export default {
  components: {
    ReferralSettings,
    Referral
  },
  data() {
    return {
      propKey: 0,
      activeName: 'referrals'
    };
  }
};