import store from "@/state/store.js";
import Storage from "@/services/storage.js";
let storage = new Storage();
export default {
  data() {
    return {
      editReferralModal: false,
      tableLoading: false,
      updateReferralLoading: false,
      referralDetails: {
        type: "",
        amount: ""
      },
      rules: {
        type: [{
          required: true,
          message: "Enter referral type",
          trigger: "blur"
        }],
        amount: [{
          required: true,
          message: "Enter referral amount",
          trigger: "blur"
        }]
      },
      search: ""
    };
  },
  computed: {
    referralSettings() {
      return store.getters["admin/referrals/getReferralSettings"].filter(item => {
        return item.type.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  mounted() {
    this.tableLoading = true;
    store.dispatch("admin/referrals/getReferralSettings").then().catch(() => {
      this.$message.error("Error fetching referral settings");
    }).finally(() => {
      this.tableLoading = false;
    });
  },
  methods: {
    editReferralType(scope) {
      this.referralDetails = {
        type: scope.type,
        amount: scope.amount,
        user_id: storage.getSavedState("auth.admin").user_id
      };
      this.editReferralModal = true;
    },
    confirmUpdate() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.updateReferralLoading = true;
          store.dispatch("admin/referrals/updateReferralSettings", this.referralDetails).then(data => {
            this.$message({
              type: "success",
              message: "Referral type updated successfully"
            });
            this.editReferralModal = false;
            this.refreshData();
          }).finally(() => {
            this.updateReferralLoading = false;
          });
        } else {
          this.updateReferralLoading = false;
          return false;
        }
      });
    },
    refreshData() {
      this.tableLoading = true;
      store.dispatch("admin/referrals/getReferralSettings").then().catch(() => {
        this.$message.error("Error fetching referral settings");
      }).finally(() => {
        this.tableLoading = false;
      });
    }
  }
};