var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "search"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Search",
      "clearable": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      "data": _vm.referralSettings
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Type",
      "prop": "type"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Amount",
      "prop": "amount"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('el-button', {
          attrs: {
            "size": "small",
            "type": "danger"
          },
          on: {
            "click": function ($event) {
              return _vm.editReferralType(scope.row);
            }
          }
        }, [_vm._v("Edit Referral Type")])];
      }
    }])
  })], 1), _c('el-dialog', {
    attrs: {
      "visible": _vm.editReferralModal,
      "title": "Edit Referral Type"
    },
    on: {
      "update:visible": function ($event) {
        _vm.editReferralModal = $event;
      }
    }
  }, [_c('div', {
    staticClass: "edit-bundle-type"
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.referralDetails,
      "label-width": "120px",
      "rules": _vm.rules,
      "label-position": "top"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Type",
      "prop": "type"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.referralDetails.type,
      callback: function ($$v) {
        _vm.$set(_vm.referralDetails, "type", $$v);
      },
      expression: "referralDetails.type"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Amount",
      "prop": "amount"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.referralDetails.amount,
      callback: function ($$v) {
        _vm.$set(_vm.referralDetails, "amount", $$v);
      },
      expression: "referralDetails.amount"
    }
  })], 1)], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        _vm.editReferralModal = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "danger",
      "loading": _vm.updateReferralLoading
    },
    on: {
      "click": _vm.confirmUpdate
    }
  }, [_vm._v("Update")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };