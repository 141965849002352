import "core-js/modules/es.array.push.js";
import store from "@/state/store.js";
export default {
  data() {
    return {
      tableLoading: false,
      search: ""
    };
  },
  computed: {
    referralData() {
      return store.getters["admin/referrals/getReferralData"].data.filter(data => data.referrer.name.toLowerCase().includes(this.search.toLowerCase()) || data.referrer.phone == this.search);
    }
  },
  methods: {
    redeem(data) {
      this.tableLoading = true;
      store.dispatch("admin/referrals/redeemReferral", {
        referrer_id: data.referrer.id
      }).then(data => {
        this.$message.success("Referral redeemed successfully");
        this.refreshData();
      }).catch(() => {
        this.$message.error("Error redeeming referral");
      }).finally(() => {
        this.tableLoading = false;
      });
    },
    viewDetails(user) {
      this.$router.push(`/admin/referrals/user/${user.referrer.id}`).catch(() => {});
    },
    refreshData() {
      this.tableLoading = true;
      store.dispatch("admin/referrals/getReferrals").then(data => {}).catch(() => {
        this.$message.error("Error fetching user referrals");
      }).finally(() => {
        this.tableLoading = false;
      });
    }
  },
  mounted() {
    this.refreshData();
  }
};